import styled from '@emotion/styled';
import { flexColContainerStyles } from 'commonStyles';
import { DOM_STATE } from 'components/constants';
import Cta from 'components/Cta';
import { richTextElements } from 'constants/common';
import { MEDIA_QUERIES } from 'globalStyles';
import { themev2 } from 'theme-v2';
import { theme } from 'theme-v2/theme';
import hideable from 'utils/hideable';

export const PricingCardsWrapper = styled.div<{
  backgroundColor?: string;
}>(({ backgroundColor }) => ({
  ...flexColContainerStyles,
  backgroundColor,
  alignItems: 'center',
  width: '100%',
  padding: `${theme.spacings.sectionSpacing} 20px`,
}));

export const Title = styled.div<{
  isSubtitleAdded: boolean;
  isToggleVisible: boolean;
}>(({ isSubtitleAdded, isToggleVisible }) => ({
  'h1,h2,h3,h4,h5,h6': {
    fontFamily: theme.fonts.family.main,
    fontSize: theme.fonts.size.h2,
    fontWeight: 600,
    textAlign: 'center',
    marginBottom: isSubtitleAdded
      ? theme.spacings.S
      : !isToggleVisible
      ? '80px'
      : '36px',
    color: theme.colors.black.S900,
  },
}));

export const Subtitle = styled.div({
  maxWidth: '600px',
  alignSelf: 'center',
  marginBottom: '36px',
  'p, em, strong': {
    fontFamily: theme.fonts.family.additional,
    fontSize: theme.fonts.size.p_medium,
    color: theme.colors.neutral.N80,
    textAlign: 'center',
    margin: 0,
  },
});

export const ToggleButtonContainer = styled.div<{
  isSecondActive: boolean;
}>(({ isSecondActive }) => ({
  position: 'relative',
  width: '224px',
  height: '38px',
  borderRadius: '24px',
  padding: '2px',
  border: '1px solid #E7E7E7',
  alignSelf: 'center',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  marginBottom: isSecondActive ? '24px' : '80px',
  '::before': {
    content: '""',
    position: 'absolute',
    background: theme.colors.black.S900,
    width: '105px',
    height: '34px',
    borderRadius: '24px',
    transform: isSecondActive ? 'translateX(108%)' : 'transformX(0)',
    transition: '0.3s',
    zIndex: -1,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    marginBottom: '32px',
  },
}));

export const Tab = styled.span<{ isActive: boolean }>(({ isActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '105px',
  height: '34px',
  cursor: 'pointer',
  color: isActive ? theme.colors.white.default : theme.colors.black.S900,
  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_medium,
  lineHeight: theme.fonts.lineHeight.p_medium,
  fontWeight: 400,
}));

export const EnterpriseFeature = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.colors.black.S900,
  width: '337px',
  height: '26px',
  justifyContent: 'center',
  borderRadius: '24px',
  marginTop: '24px',
});

export const PaymentMethodsSpacing = styled.div({
  marginTop: '48px',
});

export const PaymentMethodsContainer = styled.div({
  margin: '0 auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  maxWidth: '948px',
  gap: theme.spacings.S,
  [MEDIA_QUERIES.TABLET]: {
    flexDirection: 'column',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    gap: '32px',
    padding: '0 20px',
  },
});

export const SecurePayment = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '15px',
});

export const PaymentMethodsText = styled.p({
  fontFamily: theme.fonts.family.main,
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_small,
  color: theme.colors.gray.S500,
  marginBottom: '14px',
  fontWeight: 400,
  ':last-of-type': {
    marginBottom: 0,
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
  },
});
export const IconsText = styled(PaymentMethodsText)({
  textAlign: 'right',
  [MEDIA_QUERIES.TABLET]: {
    textAlign: 'center',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    textAlign: 'left',
  },
});
export const PaymentIcons = styled.div({
  opacity: 0.5,
  display: 'flex',
  gap: theme.spacings.S,
  marginBottom: theme.spacings.XS,
  [MEDIA_QUERIES.TABLET]: {
    marginBottom: theme.spacings.S,
  },
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  [MEDIA_QUERIES.PHONE_PORTRAIT]: {
    justifyContent: 'space-evenly',
  },
});

export const PricingCardsContainer = styled.div<{ itemsLength: number }>(
  ({ itemsLength }) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(${itemsLength >= 3 ? 3 : 2}, 300px)`,
    justifyContent: 'center',
    gap: theme.spacings.S,
    width: '100%',
    maxWidth: '1024px',
    padding: '0 20px',
    zIndex: 1,
    [MEDIA_QUERIES.TABLET]: {
      gridTemplateColumns: 'repeat(2, 300px)',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      gridTemplateColumns: '1fr',
    },
  })
);

export const PricingCardContainer = styled.div<{
  isMostPopular: boolean;
  isFlagAdded: boolean;
}>(({ isMostPopular, isFlagAdded }) => ({
  marginTop: isFlagAdded ? theme.spacings.M : 0,
  position: 'relative',
  background: theme.colors.white.default,
  ...flexColContainerStyles,
  alignItems: 'center',
  height: '362px',
  width: '300px',
  borderRadius: '10px',
  boxShadow: '0px 4px 16px rgba(29, 29, 31, 0.08)',
  border: isMostPopular
    ? `1px solid ${theme.colors.black.S900}`
    : '1px solid #E8E8ED',
  padding: '36px 24px',
  alignSelf: 'flex-end',
  justifyContent: 'flex-end',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    margin: '0 auto',
    marginTop: isFlagAdded ? theme.spacings.M : theme.spacings.S,
  },
}));

export const CardTitle = styled.h4<{
  isDiscountAdded: boolean;
  isIconAdded: boolean;
}>(({ isDiscountAdded, isIconAdded }) => ({
  fontSize: theme.fonts.size.h4,
  lineHeight: '24px',
  fontWeight: 400,
  color: theme.colors.black.S900,
  marginBottom: isDiscountAdded || isIconAdded ? 0 : '80px',
}));

export const SaveBadge = styled.span({
  background: '#00F0D3',
  padding: '2px 8px',
  borderRadius: '24px',
  color: theme.colors.black.S900,
  textTransform: 'uppercase',
  fontSize: '10px',
  lineHeight: '12px',
  fontWeight: 500,
  marginBottom: '24px',
  marginTop: '36px',
  fontFamily: themev2.fonts.family.additional,
  letterSpacing: '1px',
});

export const PriceWrapper = styled.span({
  display: 'flex',
  gap: '12px',
  alignItems: 'center',
  fontWeight: 500,
  marginBottom: '14px',
});

export const OldPrice = styled.p({
  color: theme.colors.red.default,
  textDecoration: 'line-through',
  fontSize: theme.fonts.size.p_small,
});

export const NewPrice = styled.p({
  fontSize: '46px',
  lineHeight: 1,
  color: theme.colors.black.S900,
});

export const TimePeriod = styled.small({
  color: theme.colors.gray.default,
  fontSize: theme.fonts.size.p_small,
  fontWeight: 400,
  marginLeft: '4px',
});

export const TotalPriceWrapper = styled.span({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.gray.default,
  fontWeight: 300,
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  height: '30px',
  'p, strong, em': {
    margin: 0,
    color: theme.colors.gray.default,
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    fontWeight: 300,
  },
});

export const CrossedOutPrice = styled.small({
  fontWeight: 300,
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  margin: '0 2px',
  textDecoration: 'line-through',
});
export const MoneyBack = styled.div({
  display: 'flex',
  gap: '10px',
  alignItems: 'center',
  color: '#B8B8B8',
  fontSize: theme.fonts.size.p_small,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  fontWeight: 300,
  marginTop: '16px',
  textTransform: 'uppercase',
  height: '18px',
});

export const FeaturesList = styled.div<{ itemsLength: number }>(
  ({ itemsLength }) => ({
    display: 'flex',
    flexDirection: itemsLength % 3 === 0 ? 'row' : 'column',
    justifyContent: 'flex-start',
    rowGap: itemsLength > 3 ? theme.spacings.XS : '16px',
    columnGap: '16px',
    flexWrap: 'wrap',
    [MEDIA_QUERIES.TABLET]: {
      flexDirection: 'column',
    },
  })
);

export const FeaturesContainer = styled.div<{ itemsLength: number }>(
  ({ itemsLength }) => ({
    ...flexColContainerStyles,
    order: 1,
    justifyContent: 'center',
    padding: '36px 0',
    paddingBottom: itemsLength % 3 === 0 ? 0 : '36px',
    width: itemsLength % 3 === 0 ? '100vw' : 'auto',
    maxWidth: '910px',
    maxHeight: '362px',
    [MEDIA_QUERIES.TABLET]: {
      width: 'auto',
    },
    [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
      margin: '0 auto',
      maxHeight: 'unset',
      width: '300px',
    },
  })
);

export const FeatureTitle = styled.p({
  fontSize: theme.fonts.size.p_medium,
  color: theme.colors.black.S900,
  fontWeight: 500,
  marginBottom: theme.spacings.S,
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    fontSize: theme.fonts.size.h4,
    lineHeight: theme.fonts.lineHeight.h4,
    fontWeight: 400,
    textAlign: 'left',
  },
});
export const CtaContainer = styled.div({
  width: '252px',
  [MEDIA_QUERIES.PHONE_LANDSCAPE]: {
    a: {
      width: '100%',
    },
  },
});

export const StyledCta = styled(Cta)<{ isButtonBlue: boolean }>(
  ({ isButtonBlue }) => ({
    position: 'relative',
    marginTop: '36px',
    marginBottom: 0,
    width: '252px',
    height: '42px',
    borderRadius: '4px',
    padding: 0,
    fontSize: theme.fonts.size.p_small,
    fontWeight: 500,
    lineHeight: 0,
    fontFamily: theme.fonts.family.main,
    svg: {
      position: 'absolute',
      right: '28px',
      bottom: '13px',
      path: {
        fill: isButtonBlue
          ? theme.colors.white.default
          : theme.colors.blue.default,
      },
    },
    [DOM_STATE.HOVER]: {
      padding: 0,
      transition: '0.4s',
      borderWidth: '1px',
      opacity: 0.8,
      svg: {
        right: '25px',
        transition: '0.4s',
      },
    },
    [MEDIA_QUERIES.TABLET]: {
      width: '252px',
    },
  })
);

export const CardFlag = styled.span<{ isMostPopular }>(({ isMostPopular }) => ({
  position: 'absolute',
  top: '-32px',
  paddingBottom: '4px',
  background: isMostPopular ? theme.colors.black.S900 : '#E8E8ED',
  width: '300px',
  height: '38px',
  borderTopRightRadius: '12px',
  borderTopLeftRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: -1,
  fontSize: themev2.fonts.size.p_small,
  fontWeight: 300,
  letterSpacing: '0.2em',
  color: isMostPopular ? theme.colors.white.default : theme.colors.black.S900,
}));

export const IconContainer = styled.div({
  ...flexColContainerStyles,
  alignItems: 'center',
  marginTop: '46px',
});

export const IconWrapper = styled.div({
  width: '48px',
  height: '48px',
});

export const IconText = styled.p({
  fontSize: theme.fonts.size.p_xsmall,
  lineHeight: theme.fonts.lineHeight.p_xsmall,
  fontWeight: 300,
  color: theme.colors.gray.S500,
  textAlign: 'center',
  marginTop: theme.spacings.S,
});

export const TopLabelComponent = styled.div({
  width: 'fit-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: '8px',
});

export const ValidationTextComponent = styled.div({
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacings.S,
  [richTextElements]: {
    color: theme.colors.neutral.N50,
    fontSize: theme.fonts.size.p_xsmall,
    lineHeight: theme.fonts.lineHeight.p_xsmall,
    fontWeight: 400,
  },
  strong: {
    fontWeight: 500,
  },
  a: {
    color: themev2.colors.blue.default,
  },
});

export const Feature = styled.div({
  display: 'flex',
  color: theme.colors.black.S900,
  fontSize: theme.fonts.size.p_small,
  p: {
    marginLeft: theme.spacings.XS,
  },
  div: {
    width: '21px',
    height: '21px',
    img: {
      width: '21px',
      height: '21px',
    },
  },
});

export const HideableEnterpriseFeature = hideable(EnterpriseFeature);
export const HideableSaveBadge = hideable(SaveBadge);
export const HideableOldPrice = hideable(OldPrice);
export const HideableTotalPrice = hideable(TotalPriceWrapper);
export const HideableIconContainer = hideable(IconContainer);
export const HideablePriceWrapper = hideable(PriceWrapper);
export const HideableCardFlag = hideable(CardFlag);
export const HideableSubtitle = hideable(Subtitle);
